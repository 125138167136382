
export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = ( value, rules ) => {
    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /\d+,*\d?/;
        isValid = pattern.test( value ) && isValid
    }

    return isValid;
}


export const  multiplyNumeric = (ing_name,new_quantity,ingredient_raw,pet_to_feed) => {



        const new_data_with_uncompromised_value = ingredient_raw.filter(ing => ing.name === ing_name)[0]


        let new_canvas = {...new_data_with_uncompromised_value}
        for (let key in new_canvas) {
            if ([ 'id','name','ingredient_type','ingredient_prescription_default_fr','integer_type_and_color_for_sorting','ingredient_suggested_quantity'].includes(key)){
                //do nothing
        }

            else {
                let new_value = (new_data_with_uncompromised_value[key]*new_quantity/100).toFixed(1)
                    new_canvas[key] = new_value

            //il faut mettre un par tout parce qu'on multiplie tout par un .1 *100
        }
      }

        new_canvas['energy_percentage_kcal'] = (new_quantity*new_data_with_uncompromised_value['energy_kcal']/pet_to_feed.adjusted_energy).toFixed(1)
        new_canvas['quantity'] = new_quantity
        return new_canvas

    };


export const createQuerystring = (params) => {
    return Object.keys(params).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
}


export const checkCovered = (ingredient,ing_type,type) => {
    return ingredient.filter((ing) => (ing[type] === ing_type)).length > 0;
}

export function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


export const cleanDietandPetHistory = () => {
    window.localStorage.removeItem('recipe')
    window.localStorage.removeItem('pet')
    window.localStorage.removeItem('general_prescription')
    window.localStorage.removeItem('ingredients_prescription')


}
