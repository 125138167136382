import jwtDecode from "jwt-decode";
import moment from "moment";

export const isExpired = (token) => {
    if (!token) return true
    else {
        const expiration = jwtDecode(token).exp
          return moment.unix(expiration) - moment(Date.now()) < 0

    }
}





