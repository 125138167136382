import React from 'react';
import {Route, Redirect, useParams,useLocation} from 'react-router-dom';
import {useAuthDataContext} from "../../AuthProvider";

const PrivateRoute = ({ component, ...options }) => {
  const { authData } = useAuthDataContext();
  //console.log("********* authData_private_route=",authData)
  const location = useLocation();
  console.log("location_private_routing=",location)

  if (!authData.isAuthenticated) return  <Redirect to={{pathname: "login", state: { referrer: location }}} />
  else if (authData.isAuthenticated && (authData.has_access===false||authData.has_access==='false')) return <Redirect to={`/user`}/>
  else {
    return <Route {...options} component={component} />;
  }

};
export default PrivateRoute