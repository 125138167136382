import React, { Suspense } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import Dashboard from "./components/Dashboard/Dashboard";
import LoadingSpinner from './components/UtilityComponent/LoadingSpinner'
import PrivateRoute from "./components/Route/PrivateRouting";
import SemiPrivateRoute from "./components/Route/SemiPrivateRouting";
import AuthDataProvider from './AuthProvider'

const DietHistory = React.lazy(()=>{
    return import ("./components/DietHistory/DietHistory")
})

const Diet = React.lazy(()=>{
    return import ("./components/Diet/Diet")
})

const PetFood = React.lazy(()=>{
    return import ("./components/PetFood/PetFood")
})


const Login = React.lazy(()=>{
    return import ("./components/Login/Login")
})

const Payment = React.lazy(()=>{
    return import ("./components/Payment/Payment")
})


const AddPet = React.lazy(()=>{
    return import ("./components/AddPet/AddPet")
})

const PetsList = React.lazy(()=>{
    return import ("./components/PetsList/PetsList")
})

const User = React.lazy(()=>{
    return import ("./components/User/User")
})

const IngredientLayout = React.lazy(()=>{
    return import ("./components/Ingredients/IngredientLayout")
})

const Checkout = React.lazy(()=>{
    return import ("./components/Checkout/Checkout")
})

const SignUp = React.lazy(()=>{
    return import ("./components/Signup/Signup")
})

const ForgotPassword = React.lazy(()=>{
    return import ("./components/ForgotPassword/ForgotPassword")
})

const ResetPassword = React.lazy(()=>{
    return import ("./components/ResetPassword/ResetPassword")
})

const App = () => {

    let routes = (
        <Switch>
            <SemiPrivateRoute path="/user"  component={User} />
            <PrivateRoute path="/diet" component={Diet} />
          <PrivateRoute path="/diet_history" component={DietHistory} />
           <PrivateRoute path="/pets" component={PetsList} />
           <PrivateRoute path="/payment" component={Payment} />
            <PrivateRoute path="/checkout" component={Checkout} />
           <PrivateRoute path="/newpet" component={AddPet} />
          <PrivateRoute path="/modify_pet" component={AddPet} />
           <PrivateRoute path="/ingredients" component={IngredientLayout} />
            <PrivateRoute path="/petfood" component={PetFood} />
            <Route path="/login" render={props => <Login {...props} />}/>
            <Route path="/signup" render={props => <SignUp {...props} />}/>
            <Route path="/forgot-pwd" render={props => <ForgotPassword {...props} />}/>
            <Route path="/reset-pwd/:token" render={props => <ResetPassword {...props} />}/>
            <Redirect exact from='/' to='/diet_history'/>

        </Switch>);
    //TODO = reprendre le bloc d'identification en entier
    //TODO = this is awful this double <suspense>
    return     <AuthDataProvider><Suspense fallback={<LoadingSpinner />}><Dashboard><Suspense fallback={<LoadingSpinner />}>{routes}</Suspense></Dashboard></Suspense></AuthDataProvider>
}

export default withRouter(App);
