import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {useAuthDataContext} from "../../AuthProvider";

const SemiPrivateRoute = ({ component:Component, ...options }) => {
  const {authData} = useAuthDataContext();
  if (authData.isAuthenticated) {
    return <Route {...options} render={
      props => <Component {...options} {...props}  />
  }/>
  }
  else return <Redirect to='/login'/>
}
export default SemiPrivateRoute