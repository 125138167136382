import React, { createContext, useState, useEffect, useMemo, useContext } from "react";
import {isExpired} from './components/utility/token'
import useFetch from "use-http";
import {url} from './components/utility/url'
import {cleanDietandPetHistory} from "./components/utility/utility";
import {Redirect} from "react-router-dom";

export const AuthDataContext = createContext(null);

const initialAuthData = {
         'token' : localStorage.getItem("token"),
         'refresh' : localStorage.getItem("refresh"),
          'isAuthenticated': !(isExpired(localStorage.getItem("token"))),
           'has_access':localStorage.getItem("has_access"),
    };

const AuthDataProvider = props => {
  const [authData, setAuthData] = useState(initialAuthData);
  const [request_refreshToken,response_refreshToken] = useFetch(url);

  const isExpiredToken = isExpired(localStorage.getItem("token"))
  const options =  {headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
                  cachePolicy: 'no-cache'  }; // user we cannot perform updates without the first data being used
  const [request_user,response_user] = useFetch(url,options);


  async function refreshToken(refresh) {
          let bodyFormData = new FormData();
           bodyFormData.append('refresh', refresh.toString());
            const token = await request_refreshToken.post('/api/user/token_jwt/refresh/',bodyFormData)
        if (response_refreshToken.ok) {
            return {'token':token.access,'has_access':token.has_access}
        }
        else return null

    }

    const refreshHasAccess = (currentAuthContext,user)=> {
      let AuthContext = {...currentAuthContext}
        AuthContext['has_access']=user.has_access
        return setAuthData(AuthContext)
    }

    async function refresHasAccess(currentAuthContext) {
        const user = await request_user.get('/api/user/me/')
        if (response_user.ok) {
            currentAuthContext['has_access']=user.has_access
            return setAuthData(currentAuthContext)
        } else return null

    }

  /* The first time the component is rendered, it tries to
   * fetch the auth data from a source, like a cookie or
   * the localStorage.
   */

  //Note : to be authenticated, the token must be : isExpired ==False
  useEffect(() => {
      //console.log("dans UseEffect")
      let token = localStorage.getItem("token")
      let refresh = localStorage.getItem("refresh")

      if (isExpired(token) && !isExpired(refresh)){
          //console.log("On est là - token has expired mais on va refresh")
        refreshToken(refresh).then(tokenNew => {
            //console.log("tokenCapturedFromRefresh:",tokenNew)
            if (tokenNew) {
                localStorage.setItem("token",tokenNew.token)
                return setAuthData({
                'token': tokenNew.token,
                'refresh': refresh,
                'isAuthenticated': !(isExpired(tokenNew.token)),
                 'has_access':tokenNew.has_access

            })
            }
            else return onLogout();
    })


        }
       else if (isExpired(token) && isExpired(refresh)) {
           return onLogout()
       }

       else {
           //console.log("D'accord")
           const currentAuthData = {
               'token': token,
               'refresh': refresh,
               'isAuthenticated': !(isExpired(token)),
               'has_access': false
           }
       refresHasAccess(currentAuthData)


      }


  }, [isExpiredToken]);

  const onLogout = () => {
      setAuthData(initialAuthData)
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      cleanDietandPetHistory()

  };

  const onLogin = newAuthData => {
      //console.log("onLongin")
         localStorage.setItem("token",newAuthData.access.toString());
         localStorage.setItem("refresh",newAuthData.refresh.toString());

      return setAuthData(
          {
              'token': newAuthData.access.toString(),
              'refresh': newAuthData.refresh.toString(),
              'isAuthenticated': !(isExpired(newAuthData.access.toString())),
               'has_access': newAuthData.has_access,

          })
  }

    return <AuthDataContext.Provider value={{ authData, onLogin, onLogout,refreshHasAccess }} {...props} />;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;
