import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";

export default function LoadingSpinner(size) {
  let sizeToPass=80
  if (size) sizeToPass=size
  return (
  <Grid   container
  spacing={0}
  direction="column"
  alignItems="center"
  justify="center"
>      <CircularProgress size={80} />
  </Grid>
  );
}
