import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PetsIcon from '@material-ui/icons/Pets';
import AddIcon from '@material-ui/icons/Add';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import ListIcon from '@material-ui/icons/List';
import {NavLink} from "react-router-dom";
import classes from './dashboard.module.css'
import PersonIcon from '@material-ui/icons/Person';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import { Translation } from 'react-i18next';
import OutdoorGrillIcon from '@material-ui/icons/OutdoorGrill';

const MainListItems = () => (
  <div>
                  <NavLink
          className={classes.link}
            to={'/user'}
>
    <ListItem button>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
        <Translation>
      {
        (t, { i18n }) => <ListItemText primary={t('Dashboard.title.my_profile')}/>
      }
    </Translation>

    </ListItem>
                </NavLink>
           <NavLink
          className={classes.link}
            to={'/newpet'}
>
      <ListItem button>
      <ListItemIcon>
        <AddIcon />
      </ListItemIcon>
 <Translation>
      {
        (t, { i18n }) => <ListItemText primary={t('Dashboard.title.new_pet')}/>
      }
    </Translation>
    </ListItem>
     </NavLink>

            <NavLink
          className={classes.link}
            to={'/diet'}
      ><ListItem button>
      <ListItemIcon>
        <RestaurantIcon />
      </ListItemIcon>
 <Translation>
      {
        (t, { i18n }) => <ListItemText primary={t('Dashboard.title.make_diet')}/>
      }
    </Translation>
    </ListItem>
      </NavLink>
          <NavLink
          className={classes.link}
            to={'/diet_history'}
>
    <ListItem button>
      <ListItemIcon>
        <ListIcon />
      </ListItemIcon>
 <Translation>
      {
        (t, { i18n }) => <ListItemText primary={t('Dashboard.title.my_recipes')}/>
      }
    </Translation>
    </ListItem>
                </NavLink>

            <NavLink
          className={classes.link}
            to={'/pets'}
>
    <ListItem button>
      <ListItemIcon>
        <PetsIcon />
      </ListItemIcon>
 <Translation>
      {
        (t, { i18n }) => <ListItemText primary={t('Dashboard.title.my_pets')}/>
      }
    </Translation>
    </ListItem>
                </NavLink>
                  <NavLink
          className={classes.link}
            to={'/petfood'}
>
      <ListItem button>
      <ListItemIcon>
        <StoreMallDirectoryIcon />
      </ListItemIcon>
 <Translation>
      {
        (t, { i18n }) => <ListItemText primary={t('Dashboard.title.industrial_petfood')}/>
      }
    </Translation>
    </ListItem>
     </NavLink>

      <NavLink
          className={classes.link}
            to={'/ingredients'}
>
      <ListItem button>
      <ListItemIcon>
        <OutdoorGrillIcon />
      </ListItemIcon>
 <Translation>
      {
        (t, { i18n }) => <ListItemText primary={t('Dashboard.title.ingredients')}/>
      }
    </Translation>
    </ListItem>
     </NavLink>

  </div>
);

export default MainListItems;